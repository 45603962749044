// Import CSS
import styles from '../css/app.pcss';

// Import images
import logoImg from '../img/logo-aceadvocates.png';
import headerImg from '../img/bg-header.png';

import Embed from 'v-video-embed';

// Import Formie's JS
import { Formie } from '../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: Embed } = await import(/* webpackChunkName: "vvideoembed" */ 'v-video-embed');
  Vue.use(Embed);  
  // Create our vue instance
  const vm = new Vue({
      el: "#page-container",
      components: {
        'global-header': () => import(/* webpackChunkName: "global-header" */ '../vue/components/Global/Header.vue'),
      },
      data() {
        return {
          showModal: false
        }
      },
      methods: {
      },
      mounted() {
        // Initialise any Formie forms on a page
        window.Formie = new Formie();
        window.Formie.initForms();    
      },
  });
  
  return vm;
};



// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}